.iframe {
    text-align: center;
  }
  
  .button1 {
    margin-left: 187px;
    text-align: center;
  }

  .but{
    border: 2px solid #a53892;
    background: #a53892;
    padding: 5px 40px;
    border-radius: 30px;
    margin-left: 95px;

  }
  
  .contact-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .contact-box {
    width: 380px;
    height: 250px;
    padding: 30px;
    background: black;
    color: white;
    margin: 10px;
    margin-top: 70px;
    transition: 0.3s;
    border-radius: 5px;
    border: 2px solid #a53892;
  }
  
  .contact-box:hover {
    transform: scale(105%);
    z-index: 2;
    background-color: black;
  }
  
  .contact-box img {
    width: auto;
    height: 40px;
  }
  
  .contact-box title {
    text-shadow: 2px 3px 2px white;
  }
  
  textarea {
    height: 100px;
    width: 200px;
  }

  .h{
    margin-top: 50px;
  }
  
  .form {
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 70px;
    padding: 20px;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0, 5);
    background: rgba(255, 255, 255, 0.1);
    width: 1180px;
    backdrop-filter: blur;
    border-radius: 30px;
    margin-bottom: 50px;
  }
  
  .form .send span {
    position: absolute;
    bottom: 12px;
    right: 17px;
    font-size: 14px;
  }
  
  .form input {
    color: white;
    width: 150px;
    height: 40px;
    padding: 10px;
    outline: none;
    font-size: 15px;
    margin-bottom: 10px;
    background: none;
    border: none;
    border-bottom: 2px solid white;
  }
  
  table tr td {
    padding-left: 50px;
  }
  
  .form input::placeholder {
    color: white;
  }
  
  .form textarea {
    color: white;
    width: 200px;
    height: 100px;
    padding-left: 10px;
    outline: none;
    font-size: 15px;
    margin-bottom: 10px;
    background: none;
    border: none;
    border-bottom: 2px solid white;
  }
  
  .form textarea::placeholder {
    color: white;
    font-size: 15px;
  }
  
  label {
    direction: ltr;
    padding: 5px;
    font-weight: bold;
  }
  
  .send {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .send label {
    font-weight: bold;
    font-size: 30px;
    color: white;
    text-align: center;
  }
  
  .body {
    background-color: black;
    font-family: Arial, Helvetica, sans-serif;
  }

#root{
  background: black;
}
