
.background {
  position: relative;
  background-image: url('../images/bg1.png');
  background-size: 50%;
  background-position: top left; 
  background-repeat: no-repeat;
  transition: background-position .7s ease; 
 
}

.background:hover {
  background-image: url('../images/bg2.png');
  background-position: bottom right; 
}

.banner{
  background-image: url('../images/ET-BG.png');
  background-size: cover;  
}

/* .box-shadow {
  background-color: white;
  border: none;
  color: black;
  box-shadow: 0px 20px 20px rgba(0,0,0,0.2), 0 0 50px rgba(0, 0, 0, 0.2);

} */


.awesome{
  font-size: 5rem;
  color: #555;
  letter-spacing: 1.3rem;
}

.awesome span{
  display: table-cell;
  animation: animateText 4s linear infinite;
}

span:nth-child(1){
  animation-delay: .5s;
}
span:nth-child(2){
  animation-delay: .75s;
}
span:nth-child(3){
  animation-delay: 1s;
}
span:nth-child(4){
  animation-delay: 1.25s;
}
span:nth-child(5){
  animation-delay: 1.5s;
}
span:nth-child(6){
  animation-delay: 1.75s;
}
span:nth-child(7){
  animation-delay: 2s;
}
span:nth-child(8){
  animation-delay: 2.25s;
}
span:nth-child(9){
  animation-delay: 2.5s;
}
span:nth-child(10){
  animation-delay: 2.75s;
}
span:nth-child(11){
  animation-delay: 3.s;
}
span:nth-child(12){
  animation-delay: 3.25s;
}
span:nth-child(13){
  animation-delay: 3.5s;
}



@keyframes animateText{
  0% {
      color: #fff;
      filter: blur(2px);
      text-shadow: 0 0 10px #dc143c, 0 0 20px #dc143c, 0 0 40px #dc143c,
      0 0 80px #dc143c, 0 0 100px #dc143c, 0 0 120px #dc143c, 0 0 200px #dc143c;
  }

  5%{
      color: #fff;
      filter: blur(0px);
      text-shadow: none;

  }
  95%{
      color: #fff;
      filter: blur(0px);
      text-shadow: none;
      
  }
  100%{
      color: #fff;
      filter: blur(2px);
     
      
  }
}

.card {

background: white;
transition: 1s box-shadow;
}

.card:hover{
  box-shadow: 0 5px 35px 0px rgba(0,0,0,.1);
}
.card:hover:before, .card:hover::after{
content: '';
position: absolute;
background: #FDA8CF;
z-index: -1;
animation: 1s clockwise infinite;
}

.card:hover:after{
  background: #F3CE5E;
  animation: 2s counterclockwise infinite;
}

@keyframes clockwise {
  0% {
    top: -5px;
    left: 0;
  }
  12% {
    top: -2px;
    left: 2px;
  }
  25% {
    top: 0;
    left: 5px;    
  }
  37% {
    top: 2px;
    left: 2px;
  }
  50% {
    top: 5px;
    left: 0;    
  }
  62% {
    top: 2px;
    left: -2px;
  }
  75% {
    top: 0;
    left: -5px;
  }
  87% {
    top: -2px;
    left: -2px;
  }
  100% {
    top: -5px;
    left: 0;    
  }
}
.h-screens{
  height: 90vh;
}

@keyframes counterclockwise {
  0% {
    top: -5px;
    right: 0;
  }
  12% {
    top: -2px;
    right: 2px;
  }
  25% {
    top: 0;
    right: 5px;    
  }
  37% {
    top: 2px;
    right: 2px;
  }
  50% {
    top: 5px;
    right: 0;    
  }
  62% {
    top: 2px;
    right: -2px;
  }
  75% {
    top: 0;
    right: -5px;
  }
  87% {
    top: -2px;
    right: -2px;
  }
  100% {
    top: -5px;
    right: 0;    
  }
}

.social-medias ul li {
list-style: none;
}

.social-medias ul li {
border-radius: 50%;
background: #333;
}

.social-medias ul li::before{
background: #ffee10;
transition: .5s;
transform: scale(.9);
z-index: -1;
}

.social-medias ul li:hover::before{
transform: scale(1.1);
box-shadow: 0 0 15px #ffee10;
}

.social-medias ul li:hover{
color: #ffee10;
box-shadow: 0 0 5px #ffee10;
text-shadow: 0 0 5px #ffee10;
}

.move-anim{
animation: move 5s linear infinite;
}

@keyframes move{
0%,100%{
   transform: translateY(0);
}
50%{
  transform: translateY(30px);
}
}

.move:hover {
transition: 1s ease-in;
transform: rotate(360deg); /* Rotate 360 degrees */
}

@keyframes slide {
0%, 100% {
  transform: translateX(0);
}
50% {
  transform: scale(1) rotate(0);
}
}

.popup {
transform: scale(1) rotate(0);
opacity: 1;
transition: transform 0.8s ease, opacity 0.5s ease;
}

.popup:hover {
transform: scale(1.0) rotate(360deg);
}


.anim {
opacity: 0;
transform: translateY(100px);
animation: moveup 1s linear forwards;
}
@keyframes moveup {
100% {
  opacity: 1;
  transform: translateY(0px);
}
}

.slide_down{
opacity: 0;
transform: translateY(-100px);
animation: movedown 1s linear forwards;
}

@keyframes movedown {
100% {
  opacity: 1;
  transform: translateY(0px);
}
}

.slide_right{
opacity: 0;
transform: translateX(100px);
animation: movedforward 1s linear forwards;
}

@keyframes movedforward {
100% {
  opacity: 1;
  transform: translateY(0px);
}
}

.slide_left{
opacity: 0;
transform: translateX(-100px);
animation: movebackward 1s linear forwards;
}

@keyframes movebackward {
100% {
  opacity: 1;
  transform: translateY(0px);
}
}

.anim1{
animation-delay: 2s;
}

.anim2{
animation-delay: 3s;
}

.anim3{
animation-delay: 5s;
}

.anim4{
animation-delay: 7s;
}


.adjust{
  margin-left: -55px;
}

.part-box {
  padding: 50px 0;
  background-color: black;
}

.part-box h1 {
  font-size: 40px;
  text-align: center;
  margin-bottom: 80px;
}

.part-container {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.part-container::-webkit-scrollbar {
  display: none;
}

.part-news {
  min-width: 300px;
  margin: 0 15px;
  text-align: center;
}


.part-news img {
  width: 225px;
  height: 250px;
  object-fit: cover;
}

.part-news h4 { 
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
  margin-left: -80px;
  padding-top: 10px;
}

.part-news p {
  margin-top: 5px;
  margin-left: -80px;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  cursor: pointer;
  z-index: 10;
  padding: 10px;
  border-radius: 50%;
  font-size: 20px;
  transition: background-color 0.3s ease;
}

.slider-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.slider-button.left {
  left: 10px;
}

.slider-button.right {
  right: 10px;
}
.first-news {
  margin-left: 65px; /* Adjust the value as needed */
}

.b-height{
  height: 360px;
}

.bg-slate-400
  {
    background: rgba(255, 255, 255, 0.13);
    border: 1px solid #ffffff7e;
    border-radius: 30px;
    margin-top: 20px;
}

.b-margin{
  margin-top: 20px;
}

.b-margin1{
  margin-top: 5px;
}
.fnt-fam{
font-family: Arial, Helvetica, sans-serif;
}

.fnt-siz{
  font-size: 40px;
}

.bg-clrs{
  background-color: black;
}