.footer {
    background-color: #333;
    color: #fff;
    padding: 50px 0 30px 0;
    
  }
  .footer-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer-col-4 {
    width: 511px;
  }
  .footer-col-2 {
    width: 150px;
  }
  .footer-col-3 {
    text-align: justify;
  }
  .footer-col ul {
    list-style-type: none;
    padding: 0;
  }
  .footer-col-1 {
    padding: 20px 0;
    border-bottom: 1px solid white;
  }
  .footer-col-2 ul,
  .footer-col-3 ul {
    padding-top: 30px;
  }
  
  .footer-col-2 a:hover{
    text-decoration: underline;
  }
  
  .footer-col {
    height: 150px;
  }

  .footer-col h2{
    font-size: 22px;
  }
  
  .footer-col ul li {
    margin-bottom: 10px;
  }
  
  .footer-col ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .btn {
    display: inline-block;
    background-color: #a53892;
    color: #fff;
    font-weight: 600;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 30px;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: #7c2356;
  }
  
  .social-links img {
    display: inline-block;
    margin-top: 15px;
    margin-right: 25px;
    width: 35px;
  }
  .social-links a{
      text-decoration: none;
  }
  .social-links a:last-child {
    margin-right: 0;
  }
  .copy-right{
      padding-top: 10px;
  font-size: 12px;
  text-align: center;
  }
  
  
  @media only screen and (max-width: 576px){
    .footer-row{
      flex-direction: column;
      align-items: flex-start;
    }
    .footer h2{
      text-wrap: wrap;
    }
    .footer-col-2{
      margin-top: 40px;
      line-height: normal;
    }
    .footer-col-3{
      margin-top: 30px;
      line-height: normal;
    }
    .footer-col-1{
      margin-top: 30px;
    }
    .footer-col-1 img{
      width: 35px;
    }
    .footer-col-4 {
      width: 100%;
    }
  
  }
  
  @media only screen and (min-width: 576px) and (max-width:992px)
  {
    .footer h2{
      font-size: 20px;
    }
    .footer-col p, .footer-col li, .footer-col a {
      font-size: 15px;
    }
    .footer-col{
      line-height: 20px;
    }
  }
  