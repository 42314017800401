@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
    background-color: #a53892;   
}

h1,h2,h3,h4,h5,h6,p {
    cursor: default;
}
.line-height{
    line-height: 25px;
}

a:hover{
    color:white;
}
