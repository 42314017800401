/* Navbar.css */

nav {
  width: 100%;
  height: 100px;
  padding: 0 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: black;
 
}

nav img {
  width: auto;
  height: 38px;
  margin-top: 0px;
}

nav ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-top: 43px;
}
nav ul li {
  margin: 0 30px;
}

nav ul li a {
  text-decoration: none;
  font-size: 18px;
}

nav ul li a:hover {
  color: #a53892;
}
nav ul li a.active {
  color: #a53892;
}

.menu {
  display: none;
}
.ctn-btn {
  text-decoration: none;
  border: 2px solid #a53892;
  border-radius: 30px;
  padding:6px 10px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.ctn-btn:hover {
  background-color: #a53892;
  cursor: pointer;
  color: white;
}

@media only screen and (max-width: 576px) {
  nav {
    width: 100%;
    height: 90px;
    padding: 0 30px;
    justify-content: space-between;
  }

  nav img {
    width: auto;
    height: 35px;
  }
  .nav-list {
    display: none;
  }
  .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 3px 0;
    transition: 0.4s;
  }

  .menu-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .menu {
    list-style-type: none;
    display: none;
    margin-top: 18px;
  }

  .menu.open {
    display: block;
    flex-direction: column;
    background-color: #a53892;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    padding: 20px;
  }

  .menu.open li {
    margin: 10px 0;
    text-align: center;
  }

  .menu.open a {
    color: white;
  }
  .menu.open a:hover{
    border-bottom: 1px solid white;
  }


  .menu-icon.open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-6.5px, 6px);
  }

  .menu-icon.open .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-icon.open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-6.5px, -6px);
  }

  .ctn-btn {
    display: none;
  }
}

@media only screen and (min-width: 577px) and (max-width: 992px) {
  nav {
    width: 100%;
    height: 120px;
    padding: 0 40px;
    justify-content: space-between;
  }

  nav img {
    width: auto;
    height: 60px;
  }
  .nav-list {
    display: none;
  }
  .bar {
    width: 40px;
    height: 5px;
    background-color: white;
    margin: 3px 0;
    transition: 0.4s;
  }

  .menu-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .menu {
    list-style-type: none;
    display: none;
    margin-top: 18px;
  }

  .menu.open {
    display: block;
    flex-direction: column;
    background-color: #a53892;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    padding: 20px;
  }

  .menu.open li {
    margin: 10px 0;
    text-align: center;
    padding: 12px;
  }

  .menu.open a {
    font-size: 30px;
    color: white;
    font-weight: 600;
  }
  .menu.open a:hover{
    border-bottom: 1px solid white;
  }

  .menu-icon.open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-6px, 6px);
  }

  .menu-icon.open .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-icon.open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-6.5px, -6px);
  }

  .ctn-btn {
    display: none;
  }
}


nav ul li a.active {
  color: #a53892;
  font-weight: bold;
}

/* For the mobile menu */
.menu.open a.active {
  color: white;
  font-weight: bold;
}
