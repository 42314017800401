.services{
    overflow:hidden ;
    font-family: "Roboto", sans-serif; 
    padding-bottom: 50px;  
}
.services-title{
    margin-bottom: 35px;

    font-size: 30px;
}

.linehgt{
    margin-top: 20px;
    line-height: 30px;
    font-size: 18px;
}
.img-fluid {
   justify-content: end;
}
h2{
    font-size: 26px;
}
h3{
    font-size: 20px;
    margin: 20px 0px;
    font-weight: 600;
}
.servicesimage{
    width: 400px;
    border: none;
    margin-top: 10px;
}
.btmsp{
    margin-bottom: 30px;
}
.ft-siz{
    font-size: 40px;
    padding-bottom: 20px;
}
.ft-si{
    font-size: 25px;
}

.web{
    text-align: center;
    padding-bottom: 40px;
}
h1 , h2{
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.erp{
    text-align: center;
    background: white;
}

.ft-clr{
    color: black;
}
.bottom{
    height: 200px;

}

.dgi-overall{
    margin-top: 30px !important;
}

.spt{
    position: relative;
    background-color: white  ;
    transition: transform 2s;
    border-radius: 30px;
    /* borderhe: solid 2px; */
    margin: 0 22px 50px 20px;
    overflow: hidden;
}
.overalltitlesection{
    color: white;
}
.spt-image{
    position: absolute;
    top: 0;
}
.spt-text{
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
}
.img-cnts::-webkit-scrollbar {
    display: none;
  }
.pad{
    padding: 30px 5%;
}


.padss{
    padding-bottom: 30px;
}
.clr{
    color: white;
}

.spt-body{
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    /* background-color: white; */
    padding: 10px 30px;
    border-radius: 0 0 30px 30px;
    background-color: #a53892;
}
.spt:hover{
    .hiddentext{
        display: block;
        height: 50px;
        text-align: center;
        transition: height 0.4s;
    }
}
 
.hiddentext{
    /* display: none; */
    height: 0;
    overflow: hidden;
    
}
.pink-bg{
    background-color: #a53892  ;
}
#wht{
    color: white;
}
.white{
    color: white !important;
}
.spt:hover .spt-image{
    transform:scale(1.1) ;
    transition: transform 0.5s;
}
.spt-overall{
    /* background-color: rgb(0, 0, 0)  ; */
    display: flex;
    flex-wrap: wrap;
}

/* large screen */
@media only screen and (min-width: 993px){
    .erp-image{
        width: 250px;
        margin: 20px; 
    }
    .showobj{
        display: none;
    }
    .soc-el1{
        margin-left: 20px;
    }
    .data-image{
        width: 350px;
    }
    .spt{
        width: 16rem;
        height: 16rem;
    }
    .spt-image{
        width: 14rem;
    }
    .spt-body{
        width: 16rem;
    }
    .services-title{
        padding-top: 60px !important;
    }
}
/* medium screen */
@media only screen and (min-width: 577px) and (max-width: 992px){
    .erp-image{
        width: 250px;
        margin: 20px; 
    }
    .showobj{
        display: none;
    }
    .soc-el1{
        margin-left: 20px;
    }
    .data-image{
        width: 350px;
    }
    .spt{
        width: 16rem;
        height: 16rem;
    }
    .spt-image{
        width: 14rem;
    }
    .spt-body{

        width: 16rem;
    }
    .services-title{
        padding-top: 60px !important;
    }
}
/* mobile screen */
@media only screen and (max-width: 576px) {
    .hideinmob{
        display: none;
    }
    .hideobj{
        display: none ;
    }
    .erp-image {
        width: 200px;
        margin: 5px;
    }  
    .soc-el1{
        margin-left: 0px;
    }
    .soc-el2{
        margin-left: 10px;
    }
    .data-image{
        width: 250px;
    }
    .spt{
        width: 14rem;
        height: 14rem;
    }
    .spt-image{
        width: 12rem;
    }
    .spt-body{
        width: 14rem;
    }
}
.webimage{
    width: 100%;
    object-fit:cover;  
}
.img-cnts{
    width: 320px;
    height: 480px;
    margin: 20px;
    overflow-y: scroll;
    
}
.dtser{
    margin-top: 30px;
}

.dt-cnt{
   text-align: left;
}


.web-btn{
    margin-top: 20px;
    background-color: #a53892;
    border: 2px solid #a53892;
    border-radius: 30px;
    font-size: 18px;
    padding: 8px 25px;
    font-weight: 600;
}
.web-btn:hover{
    border: 2px solid #a53892;
}
.cnt1{
    display: flex;
    flex-wrap: wrap;
}
.bg-change{
    background-color: black;
}

.animate{
    animation: float1 infinite;
}
@keyframes float1 {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }    
}
.erp-image:hover{
    animation: mymoves 2s ;
    animation-iteration-count: 1;
    animation-timing-function: linear;
}
@keyframes mymoves{
    0%{
        transform: rotateY(0deg);
    }
    
    20%{
        transform: rotateY(45deg);
    }
    30%{        
        transform: rotateY(90deg);
    }
    40%{
        transform: rotateY(135deg);
    }
    50%{
        transform: rotateY(180deg);
    }
    60%{
        transform: rotateY(225deg);
    }
    70%{
        transform: rotateY(270deg);
    }
    80%{
        transform: rotateY(315deg);
    }
    90%{
        transform: rotateY(360deg);
    }
    100%{
        transform: rotateY(365deg);
    }
}
.data-image{
    display: flex;
    width: 250px;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}
.dt-img{
    width: 180px;
}
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 10px); }
    100%   { transform: translate(0, 0px); }    
}
.soc-overall{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 45%;
    padding-right: 140px;
    justify-content: space-around;
}

.soc-des{
    text-align: right  ;
    margin-top: 20px;
    width: 55%;
}

.soc-image{
    width: 50px ;   
}
.row{
    margin: 0px ;
}
.soc-link-cnt{
    display: flex;
    margin: 40px 0;
    /* align-items: center; */
}
.soc-text{
    margin: 20px 10px;
}
.grand{
    background-color: #828eca;
    background-image: linear-gradient(43deg, #828eca 0%, #cc76c6 46%, #ffe5b4 100%);
}
.regrand{
    background-color: #C850C0;
    background-image: linear-gradient(43deg, #FFCC70 0%, #4158D0 46%, #C850C0 100%);
}
.dgi-cnt {
   width: 90%;
   text-align: center;
}

.digi-box{
    width: 350px;
    margin: auto;
    border: 2px solid #a53892;
    border-radius: 15px;
    
}

.digi-box .center p{
    font-size: 17px;
}

.dt-imgs{
    width: 220px;
}

.digi-mar{
    margin-bottom: 60px;
}